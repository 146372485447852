import { ThemeConfig } from 'antd';

export const AntConfig: ThemeConfig = {
	token: {
		colorInfo: process.env.COLOR_INFO || '#0a2653',
		colorWarning: process.env.COLOR_WARNING || '#ea850f',
		colorSuccess: process.env.COLOR_SUCCESS || '#009b47',
		colorError: process.env.COLOR_ERROR || '#e64343',
		colorLink: process.env.COLOR_LINK || '#4587e8',
		colorTextBase: process.env.COLOR_TEXT_BASE || '#0a2653',
		colorTextTertiary: process.env.COLOR_TEXT_TERTIARY || '#0a26534e',
		colorTextSecondary: process.env.COLOR_TEXT_SECONDARY || '#0a2653a3',
		fontSize: +(process.env.FONT_SIZE || 16),
	},
	components: {
		Button: {
			colorPrimary: 'rgb(20, 72, 157)',
			colorBgContainer: 'rgb(243, 246, 250)',
			colorText: 'rgb(20, 72, 157)',
			dangerShadow: '',
			primaryShadow: '0 0 0 0 0 0 ',
			contentFontSize: 16,
			contentLineHeight: 1,
			contentLineHeightSM: 1,
			contentLineHeightLG: 1,
			controlHeight: 40,
			lineWidth: 1,
			paddingInline: 20,
			algorithm: true,
		},
		Switch: {
			handleSize: 18,
			handleSizeSM: 12,
			innerMaxMargin: 24,
			innerMaxMarginSM: 18,
			innerMinMargin: 9,
			innerMinMarginSM: 6,
			trackHeight: 22,
			trackHeightSM: 16,
			trackMinWidth: 44,
			trackMinWidthSM: 28,
			trackPadding: 2,
			colorPrimary: '#14489D',
		},
		Select: {
			motion: false,
		},
	},
};
